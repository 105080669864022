import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Card = props => {
  const { title, slug, thumbImage, thumbText, metaDescription, author, publishDate } = props

  const image = getImage(thumbImage)

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden group">
      <Link to={`/${slug}`}>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
        <GatsbyImage image={image} alt={thumbText} />
        </div>
        <div className="p-4 sm:p-5">
          <h1 className="text-2xl text-engred font-bold">{title}</h1>
          <p className="my-3 text-sm sm:text-base text-mnblue">{metaDescription}</p>
          <div className="mt-6 flex items-center">
            <div>
              <p className="my-0 text-sm serif font-medium text-pacblue">
                {author}
              </p>
              <div className="flex space-x-1 text-sm text-mnblue">
                <time dateTime="2020-03-16">
                  {publishDate}
                </time>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  thumbText: PropTypes.string.isRequired,
  author: PropTypes.string,
  datePublished: PropTypes.instanceOf(Date),
}

export default Card

export const query = graphql`
  fragment ArticleCard on ContentfulArticle {
    id
    title
    slug
    thumbImage {
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: BLURRED
        formats: [AUTO, WEBP]
      )
    } 
    thumbText
    metaDescription
    author
    publishDate(formatString: "MMMM DD, YYYY")
  }
`

